<div class="facility-part-showroom ">

    <div id="showRoomHeader" class="horizontal-layout">

        <h3 class="sub-heading centered-text">{{data.facilityElement.facilityPartName}}</h3>

        <button id="showRoomCloseButton" mat-icon-button mat-dialog-close color="primary">X</button>
 
    </div>

    <img id="facilityPartImage" [src]="data.facilityElement.imagePath">

    <div id="showRoomFooter">

        <p class="normal-text">{{data.facilityElement.description}}</p>

    </div>

</div>
