import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { FacilityPartShowroomComponent } from './facility-part-showroom/facility-part-showroom.component';
import { FacilityElement } from './FacilityElement';
import { ngIfAnimation } from 'src/app/shared/animations/ngIfAnimation';

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.css'],
  animations: [
    ngIfAnimation,
  ]
})
export class FacilityComponent implements OnInit {

  /** defines the amount of pixels needed for the window width to use the circle layout */
  private circleLayoutMinimumWindowWidth: number = 825;

  /** defines the amount of pixels needed for the window height to use the circle layout */
  private circleLayoutMinimumWindowHeight: number = 825;

  /** the available width to display the content of the page */
  private availableWindowWidthInPixels: number = window.innerWidth;

  /** the available height to display the content of the page */
  private availableWindowHeightInPixels: number = window.innerHeight;

  /** whether the view shall use the column layout or the circle layout (if false), set to true because most users will see page using mobile */
  public useColumnLayout: boolean = true;

  /** the radius of the circle layout in pixels */
  circleRadius: number = 300;

  private resizeObservable: Observable<Event>;
  private resizeSubscription: Subscription;
  private dialogCloseSubscription: Subscription;

  public currentlyActiveFacilityElement: FacilityElement = null;

  public facilityElements: FacilityElement[] = [

    {
      facilityPartName: "Zufahrt",
      imagePath: "assets/img/facility/facility_outside.JPG",
      id: 0,
      description: "Wir sind eine zahnärztliche Gemeinschaftspraxis mit den Fachgebieten Allgemeine Zahnheilkunde und Kieferorthopädie. Unsere Praxis liegt im Grünen, abseits von der Hektik der Stadt. Bequem und unkompliziert können Sie bei uns parken. Von den öffentlichen Verkehrsmitteln an der Haltestelle Leonardo-da-Vinci-Straße bzw. dem Fähranleger Pillnitz sind es nur wenige Schritte durch die Baumallee der Maillebahn bis in unsere Praxis."
    },
    {
      facilityPartName: "Warten",
      imagePath: "assets/img/facility/waiting.JPG",
      id: 1,
      description: "Zum Verkürzen der nicht immer vermeidbaren Wartezeit können Sie in Zeitschriften schmökern, für die kleinen Patienten haben wir eine kindgerechte Spielecke eingerichtet. In der warmen Jahreszeit nutzen unsere Familien mit Kindern gern die Bank im Freien vor der Praxis."
    },
    {
      facilityPartName: "Beratung",
      imagePath: "assets/img/facility/consulting.JPG",
      id: 2,
      description: "Wir nehmen uns Zeit für Sie und Ihre Kinder. Vor einer geplanten prothetischen, chirurgischen, parodontologischen oder kieferorthopädischen Behandlung erhalten Sie eine umfangreiche Beratung über die geplante Therapie sowie über möglicherweise anfallende Kosten. Wir besprechen mit Ihnen bzw. mit Ihnen und Ihrem Kind die notwendigen Behandlungsschritte. Zudem geben wir Tipps und wichtige Informationen, damit die geplante Behandlung ein Erfolg wird."
    },
    {
      facilityPartName: "Behandlung",
      imagePath: "assets/img/facility/treatment.JPG",
      id: 3,
      description: "Unser hochqualifiziertes und freundliches Praxisteam freut sich in einer ruhigen und entspannten Atmosphäre Ihnen helfen zu können. Unsere Behandlungszimmer sind klimatisiert. Wir arbeiten mit einem erprobten Hygienekonzept und modernen Behandlungsmitteln."
    },
    {
      facilityPartName: "Rezeption",
      imagePath: "assets/img/facility/reception.JPG",
      id: 4,
      description: "Über zwei Stufen erreichen Sie unsere Praxis, nicht vollständig barrierefrei. Anmeldeformalitäten und Terminabsprachen können Sie an unserer Rezeption erledigen, gern auch telefonisch oder per E-Mail."
    },
    {
      facilityPartName: "Röntgen",
      imagePath: "assets/img/facility/x-ray.JPG",
      id: 5,
      description: "Unsere Praxis ist mit digitaler Röntgentechnik ausgestattet. Bei minimaler Strahlenexposition für den Patienten stehen in Sekunden detailscharfe Bilder (OPG, Fernröntgen, Zahnfilmaufnahme) für die Diagnostik zur Verfügung."
    },

  ]

  constructor(public dialogRef: MatDialog) { }

  ngOnInit(): void {

    this.availableWindowWidthInPixels = window.innerWidth;
    this.availableWindowHeightInPixels = window.innerHeight;

    if (this.availableWindowWidthInPixels >= this.circleLayoutMinimumWindowWidth && this.availableWindowHeightInPixels >= this.circleLayoutMinimumWindowHeight) {
      this.useColumnLayout = false;
    } else {
      this.useColumnLayout = true;
    }

    // we need to wait until angular has applied the *ngIf
    setTimeout(() => {
      this.createCircleLayout();
    },0);

    this.resizeObservable = fromEvent(window, 'resize')
    this.resizeSubscription = this.resizeObservable.subscribe( evt => {

      this.availableWindowWidthInPixels = window.innerWidth;
      this.availableWindowHeightInPixels = window.innerHeight;

      if (this.availableWindowWidthInPixels >= this.circleLayoutMinimumWindowWidth && this.availableWindowHeightInPixels >= this.circleLayoutMinimumWindowHeight) {
        this.useColumnLayout = false;
      } else {
        this.useColumnLayout = true;
      }

      // close the dialog when switching to mobile layout during runtime
      if (this.useColumnLayout == true) {

        // unsubscribe from dialog close event as it would deactivate the currently active facility element
        this.dialogCloseSubscription?.unsubscribe();
        this.dialogRef.closeAll();
      }

      // we need to wait until angular has applied the *ngIf
      setTimeout(() => {
        this.createCircleLayout();
      },0);

    });

  }

  /**
   * Expand the clicked facility element (mobile version).
   * 
   * @param facilityId the id of the facility element to expand
   */
  public showFacilityPart(facilityId: number): void {

    // if the user clicked the same facility part again, deactivate it
    if (this.currentlyActiveFacilityElement != null && this.currentlyActiveFacilityElement.id == facilityId) {
      this.currentlyActiveFacilityElement = null;
      return;
    }

    // get the facility part object
    this.currentlyActiveFacilityElement = this.facilityElements.find((facilityElement: any) => {
      return facilityElement.id == facilityId;
    });

    // scroll to the top of the corresponding HTML element so that the user can focus the clicked element
    let mobileFacilityElement = document.getElementById("mobileFacilityElement_" + this.currentlyActiveFacilityElement.id);

    // wait for ngif to take effect
    setTimeout(() => {

      mobileFacilityElement.scrollIntoView({behavior: "smooth", block: "start"});

    })
  }

  /**
   * Open a dialog which shows more information about the facility element (desktop version). 
   * 
   * @param facilityId the id of the facility element to show
   */
  public showFacilityPartAsDialog(facilityId: number): void {

    // get the facility part object
    this.currentlyActiveFacilityElement = this.facilityElements.find((facilityElement: any) => {
      return facilityElement.id == facilityId;
    });

    // configure and open the dialog
    let currentDialogRef = this.dialogRef.open(FacilityPartShowroomComponent, {
      width: '50%',
      data: {
        facilityElement: this.currentlyActiveFacilityElement,
      }
    });

    // define what shall happen when the dialog closes
    this.dialogCloseSubscription = currentDialogRef.afterClosed().subscribe(result => {

      this.currentlyActiveFacilityElement = null;

    })

  }

  /**
   * Arranges the facility elements on a circle.
   * 
   */
  private arangeCircleElements(): void {

      /* get center of circle layout (the center of the circle) */

      // get the corresponding element
      let circleCenterElement: HTMLElement = document.getElementById("circleCenter");
      let circleCenterElementBoundingRect: DOMRect = circleCenterElement.getBoundingClientRect();

      // get width and heigt
      let circleCenterElementWidth = circleCenterElementBoundingRect.width;
      let circleCenterElementHeight = circleCenterElementBoundingRect.height;

      // width must be same as heigt in our case, also a workaround for a bug in chrome serving the wrong height value
      if (circleCenterElementWidth != circleCenterElementHeight) {
        circleCenterElementHeight = circleCenterElementWidth;
      }

      // calculate center position of circle
      let circleCenterX: number = circleCenterElementBoundingRect.left + circleCenterElementWidth/2 + window.scrollX;
      let circleCenterY: number = circleCenterElementBoundingRect.top + circleCenterElementHeight/2 + window.scrollY;

      // calculate angle steps for circle elements
      let angleStep = 360 / this.facilityElements.length;

      let currentAngle = 0;

      /* calculate positions of elements in the circle layout and place circle elements accordingly */
      
      for (let facilityElement of this.facilityElements) {
  
        let circleElement = document.getElementById("circleElement_" + facilityElement.id);
        
        circleElement.style.left = (circleCenterX + Math.cos(this.degreeToRadians(currentAngle)) * this.circleRadius).toString() + "px";
        circleElement.style.top = (circleCenterY + Math.sin(this.degreeToRadians(currentAngle))* this.circleRadius).toString() + "px";

        // center circle element around their center point on the circle 
        circleElement.style.left = (circleElement.getBoundingClientRect().left - circleElement.clientWidth / 2).toString() + "px";
        circleElement.style.top = (circleElement.getBoundingClientRect().top - circleElement.clientHeight / 2).toString() + "px";

        currentAngle += angleStep;
  
      }     
  }

  /**
   * Calculated the maximum possible circle radius which is dependent on the available width and height. 
   */
  private calculateMaxCircleRadius(): void {

    // the available dimensions of the parent element of the circle layout define the max radius of the circle
    let circleLayout: HTMLElement = document.getElementById("circleLayout");
    let circleLayoutParent = circleLayout.parentElement;

    let availableWidth = circleLayoutParent.getBoundingClientRect().width;
    let availableHeight = circleLayoutParent.getBoundingClientRect().height;

    // get the dimension of the circle elements
    let circleElement: HTMLElement = document.getElementById("circleElement_0");

    let circleElementWidth = circleElement.getBoundingClientRect().width;
    let circleElementHeight = circleElement.getBoundingClientRect().height;

    this.circleRadius = (availableWidth - circleElementWidth) / 2;

    // beware that the diameter of the circle + the circle element height does not exceed the available height
    if (2*this.circleRadius + circleElementHeight > availableHeight) {

      this.circleRadius = (availableHeight - circleElementHeight)/2;

    }
  }

  /**
   * Calcualtes the measures of the circle layout and places its items accordingly. 
   */
  private createCircleLayout(): void {

    if (this.useColumnLayout == true) {
      return;
    }

    // calculate the radius of the circle
    this.calculateMaxCircleRadius();

    // arrange circle elements according to circle radius
    this.arangeCircleElements();

  }

  ngOnDestroy(): void {

    this.resizeSubscription.unsubscribe();

  }

  /**
   * Converts degree to radian measure. 
   * 
   * @param degree the degree value to convert
   * @returns the radian measure of the given degree
   */
  degreeToRadians(degree: number): number {

    return degree * Math.PI / 180;

  }
}
